.delete-modal-content {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  background-color: white;
  padding: 32px;
  border-radius: 10px;
  width: calc(482px - 64px);
  max-width: 80vw;
  height: auto;
}

.modal-h {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: var(--black);
  text-align: center;
}

.delete-modal-p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: start;
  color: var(--gray2);
  margin-bottom: 32px;
}

.delete-modal-btns-div {
  display: flex;
  justify-content: end;
  width: 100%;
}

.delete-modal-primary-btn {
  display: flex;
  align-items: center;
  padding: 8px 32px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  border-radius: 10px;
  background-color: var(--red);
  color: white;
  margin-left: 8px;
}

.modal-secondary-btn {
  display: flex;
  align-items: center;
  padding: 8px 32px;
  background-color: transparent;
  border: 1px solid var(--gray3);
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  border-radius: 10px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000; /* Ensure the modal appears above other content */
}
