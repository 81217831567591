.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 100%;
}

.page-heading {
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  color: var(--yellow);
  margin: 16px 0px;
}

.novosti-search-div {
  display: flex;
  width: 100%;
  max-width: 1416px;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--yellow);
  margin-bottom: 8px;
}

.dodaj-btn-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-radius: 10px;
  background-color: var(--yellow);
  cursor: pointer;
  text-decoration: none;
}

.dodaj-btn-img {
  width: 16px;
  height: 16px;
}

.dodaj-btn-p {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: white;
  margin-left: 8px;
}

.novosti-div {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1416px;
}

.novost-div {
  display: flex;
  align-items: center;
  padding: 16px;
  width: calc(100% - 32px);
  border: 1px solid var(--gray);
  border-radius: 10px;
  margin: 8px 0px;
  position: relative;
}

.novost-img {
  min-width: 201px;
  max-width: 201px;
  height: 138px;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
  margin-right: 16px;
}

.novost-text-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
}

.novost-heading {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  color: var(--black);
}

.novost-time-p {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin: 12px 0px;
}

.novost-p {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: var(--black);
}

.novost-btns-div {
  display: flex;
  align-items: center;
  position: absolute;
  top: 16px;
  right: 16px;
}

.novost-btn-div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  border-radius: 20px;
  box-shadow: 0px 5px 10px 0px rgba(18, 36, 131, 0.14);
  margin: 0px 4px;
  background-color: white;
}

.novost-btn-img {
  width: 14px;
  height: 14px;
}

@media only screen and (max-width: 1416px) {
  .novosti-search-div {
    width: calc(100% - 32px);
    padding: 0px 16px;
    padding-bottom: 10px;
  }

  .novosti-div {
    width: calc(100% - 32px);
    padding: 0px 16px;
  }
}

@media only screen and (max-width: 600px) {
  .novosti-search-div {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }

  .novosti-search-input {
    width: 100%;
    margin: 8px 0px;
  }

  .dodaj-btn-div {
    justify-content: center;
    width: calc(100% - 32px);
  }

  .novost-div {
    flex-direction: column;
    align-items: center;
    padding: 16px;
  }

  .novost-img {
    width: 100%;
    height: 150px;
    margin-right: 0px;
  }

  .novost-text-div {
    margin-top: 16px;
  }
}
