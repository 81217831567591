.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.login-form-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  width: calc(400px - 32px);
  height: calc(500px - 32px);
  border-radius: 10px;
  background: #fff;
  box-shadow: 4px 4px 10px 4px rgba(0, 0, 0, 0.2);
}

.login-form-logo {
  width: 121px;
  height: auto;
  margin-bottom: 32px;
}

.login-form-input {
  width: 100%;
  margin: 8px 0px;
}

.login-form-helper-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.login-form-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 12px 0px;
  background-color: var(--black);
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  color: white;
}

.login-error-message {
  width: 100%;
  text-align: start;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: var(--red);
  margin: 4px 0px;
}
