.dodaj-novost-form-div {
  display: flex;
  flex-direction: column;
  width: 777px;
  height: auto;
  justify-content: start;
  padding: 16px 0px;
}

.dodaj-novost-input {
  margin-bottom: 16px;
  margin-top: 4px;
}

.picture-input-div {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 15px;
}

.picture-input {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 250px;
  justify-content: center;
  align-items: center;
  border: 1px dashed var(--gray2);
  border-radius: 10px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.border-none {
  border: none;
}

.picture-input input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.picture-output {
  display: flex;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.picture-input-placeholder {
  width: 64px;
  height: 64px;
}

.dodaj-novost-quill-div {
  width: 100%;
  margin-bottom: 16px;
  margin-top: 4px;
}

.dodaj-novost-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 12px 0px;
  background-color: var(--yellow);
  border-radius: 10px;
  cursor: pointer;
}

.dodaj-novost-cancel-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0px;
  width: 100%;
  margin: 8px 0px;
  border: 1px solid var(--gray2);
  color: var(--gray2);
  border-radius: 10px;
  text-decoration: none;
}

@media only screen and (max-width: 777px) {
  .dodaj-novost-form-div {
    width: calc(100% - 16px);
    padding: 16px 8px;
  }
}
