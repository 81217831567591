.header-div {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 74px;
  box-shadow: 4px 4px 10px 4px rgba(0, 0, 0, 0.25);
  position: fixed;
  background-color: white;
  z-index: 100;
}

.header-img {
  height: 100%;
  width: auto;
}

.mt-74 {
  margin-top: calc(74px + 16px);
}
